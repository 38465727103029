var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "page-search", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入关键字",
                      clearable: "",
                    },
                    model: {
                      value: _vm.query.key_words,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "key_words", $$v)
                      },
                      expression: "query.key_words",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.clickAddTableInfo },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "row-key": "roleId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "编号",
                  align: "center",
                  width: "60",
                  prop: "roleId",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "角色名",
                  prop: "roleName",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", "min-width": "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "权限", prop: "menuIds", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.menuIds || "--"))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              staticClass: "success",
                              attrs: {
                                type: "text",
                                icon: "el-icon-edit-outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditTableInfo(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        scope.row.roleId != 63 &&
                        scope.row.roleId != 64 &&
                        scope.row.roleId != 65 &&
                        scope.row.roleId != 66 &&
                        scope.row.roleId != 1
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.clickDelTableInfo(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.addOrEditModel.roleId ? "编辑" : "添加"}角色信息`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              staticClass: "form-wrap",
              attrs: {
                "label-position": "left",
                "label-width": "90px",
                model: _vm.addOrEditModel,
                rules: _vm.addOrEditRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名", prop: "roleName" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled:
                        _vm.addOrEditModel.roleId == 63 ||
                        _vm.addOrEditModel.roleId == 64,
                      placeholder: "请输入角色名",
                    },
                    model: {
                      value: _vm.addOrEditModel.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "roleName", $$v)
                      },
                      expression: "addOrEditModel.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色标识", prop: "roleSign" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入角色标识" },
                    model: {
                      value: _vm.addOrEditModel.roleSign,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "roleSign", $$v)
                      },
                      expression: "addOrEditModel.roleSign",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: "",
                      placeholder: "请输入备注",
                    },
                    model: {
                      value: _vm.addOrEditModel.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "remark", $$v)
                      },
                      expression: "addOrEditModel.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "菜单权限", prop: "menuIds" } },
                [
                  _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.showLoading,
                        expression: "showLoading",
                      },
                    ],
                    ref: "menuTree",
                    attrs: {
                      data: _vm.menuList,
                      "show-checkbox": "",
                      "node-key": "id",
                      props: _vm.defaultProps,
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      "default-expanded-keys": _vm.defaultShowNodes,
                    },
                    on: { check: _vm.checkMenuData },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.addOrEditBtnLoading,
                      },
                      on: { click: _vm.clickAddOrEditConfirm },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }