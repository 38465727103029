var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        plain: "",
                        type: "primary",
                        loading: _vm.showLoading,
                      },
                      on: { click: _vm.clickAddInfo },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "row-key": "id",
                height: _vm.tableHeight,
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "编号",
                  type: "index",
                  index: _vm.indexMethod,
                  align: "center",
                  width: "60",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "text", label: "名称", "min-width": "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "noticeContent",
                  label: "图标",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: scope.row.attributes.icon,
                          staticStyle: { "font-size": "16px" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addTime",
                  label: "类型",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            scope.row.type == 0
                              ? "目录"
                              : scope.row.type == 1
                              ? "菜单"
                              : "按钮"
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "url", label: "地址", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.attributes.url))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "perms", label: "权限标识", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(_vm._s(scope.row.attributes.perms))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type != 2
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "添加",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: { icon: "el-icon-plus", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickAddChildrenInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _c("el-button", {
                              staticClass: "success",
                              staticStyle: { "margin-left": "0" },
                              attrs: {
                                icon: "el-icon-edit-outline",
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditInfo(scope.row)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: "您确定要删除么？",
                                  icon: "el-icon-info",
                                  "icon-color": "red",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.clickDelInfo(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "danger",
                                  attrs: {
                                    slot: "reference",
                                    icon: "el-icon-delete",
                                    type: "text",
                                  },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.dialogType == 3 ? "编辑" : "添加"}${_vm.dialogName}`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              staticClass: "form-wrap",
              attrs: {
                "label-width": "80px",
                model: _vm.addOrEditModel,
                rules: _vm.addOrEditRules,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "上级菜单", prop: "notice_title" } },
                [
                  _c("el-input", {
                    attrs: {
                      value:
                        _vm.dialogType == 1
                          ? "根目录"
                          : _vm.addOrEditModel.parentMenu,
                      disabled: true,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addOrEditModel.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "name", $$v)
                      },
                      expression: "addOrEditModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "url" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addOrEditModel.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "url", $$v)
                      },
                      expression: "addOrEditModel.url",
                    },
                  }),
                ],
                1
              ),
              _vm.addOrEditModel.type == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "权限标识", prop: "perms" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addOrEditModel.perms,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrEditModel, "perms", $$v)
                          },
                          expression: "addOrEditModel.perms",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "排序号", prop: "orderNum" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请输入排序号" },
                    model: {
                      value: _vm.addOrEditModel.orderNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "orderNum", $$v)
                      },
                      expression: "addOrEditModel.orderNum",
                    },
                  }),
                ],
                1
              ),
              _vm.dialogType == 1 ||
              (_vm.dialogType == 3 && _vm.addOrEditModel.type == 0)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "图标", prop: "icon" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "例如：el-icon-s-custom" },
                        model: {
                          value: _vm.addOrEditModel.icon,
                          callback: function ($$v) {
                            _vm.$set(_vm.addOrEditModel, "icon", $$v)
                          },
                          expression: "addOrEditModel.icon",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.showLoading },
                      on: { click: _vm.clickAddOrEditConfirm },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-l-10",
                      on: { click: _vm.clickAddOrEditCancel },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }