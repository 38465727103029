<template>
    <div class="container">
        <div class="page-container">
            <el-table ref="table" :data="tableData" border stripe :row-key="'userId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="userId" label="编号" align="center" width="60" fixed="left">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="nickName" label="昵称" min-width="120" align="center" fixed="left">
                </el-table-column>
                <el-table-column label="用户头像" width="90" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.headImg"
                            :preview-src-list="[scope.row.headImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column prop="loginName" label="手机号" min-width="100" align="center"></el-table-column>
                <el-table-column prop="sex" label="性别" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex == 1">男</span>
                        <span v-else-if="scope.row.sex == 2">女</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="personalSignature" label="个性签名" align="center">
                    <template slot-scope="scope">
                        <a @click="showContentHandle(scope.row, 1)">
                            <el-link type="primary">查看</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="userType" label="用户类型" align="center">
                    <template slot-scope="scope">
                        {{scope.row.userType | getStateName(userTypeList)}}
                    </template>
                </el-table-column>
                <el-table-column prop="storeName" label="门店名" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.storeName || "--" }}
                    </template>
                </el-table-column>
                <el-table-column prop="subCount" label="下级数量" min-width="100" align="center">
                    <!-- <template slot-scope="scope">
                        <a @click="subUserHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.subCount }}</el-link>
                        </a>
                    </template> -->
                </el-table-column>
                <el-table-column prop="integral" label="用户积分" align="center">
                    <!-- <template slot-scope="scope">
                        <a @click="userIntegralHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.integral }}</el-link>
                        </a>
                    </template> -->
                </el-table-column>
                <el-table-column prop="circleCount" label="圈子数" align="center">
                    <!-- <template slot-scope="scope">
                        <a @click="userCircleCountHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.circleCount }}</el-link>
                        </a>
                    </template> -->
                </el-table-column>
                <el-table-column prop="newsCount" label="动态数" align="center">
                    <!-- <template slot-scope="scope">
                        <a @click="userNewsCountHandle(scope.row)">
                            <el-link type="primary">{{ scope.row.newsCount }}</el-link>
                        </a>
                    </template> -->
                </el-table-column>

                <el-table-column prop="followCount" label="关注数" align="center"></el-table-column>
                <el-table-column prop="fansCount" label="粉丝数" align="center"></el-table-column>


                <el-table-column prop="onlineState" label="在线状态" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.userType==3">
                            {{scope.row.onlineState | getStateName(onlineStateList)}}
                        </span>
                        <span v-else>--</span>
                    </template>
                </el-table-column>
                <el-table-column prop="isAutoOnline" label="是否开启自动上线" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isAutoOnline == 0 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isRecommend" label="是否推荐" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isRecommend == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isLogout" label="是否注销" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isLogout == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isOfficial" label="是否官方" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isOfficial == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="isUse" label="是否可用" min-width="80" align="center">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isUse == 0 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="registTime" label="注册时间" width="140" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.registTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectUserList">
                            <el-button class="success" type="text" icon="el-icon-circle-check"
                                @click="selectUserHandle(scope.row)"></el-button>
                        </el-tooltip>

                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>

        <!-- 详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
 
    </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import adminManager from "@/request/adminManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue";


export default {
    name: "name-list",
    mixins: [getList],
    components: {
        ShowContent,

    },
    data() {
        
        return {
            requst: {
                module: adminManager,
                url: "selectUserList",

            },
            query: {
            },
            // 【1：普通用户；2:护理师；3:专家】
            userTypeList: [
                {
                    value: 1,
                    label: "普通用户",
                },
                {
                    value: 2,
                    label: "护理师",
                },
                {
                    value: 3,
                    label: "专家",
                },
                {
                    value: 4,
                    label: "主管",
                },

            ],
            isRecommendList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isUseList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isOfficialList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            isLogoutList: [{ value: 0, label: "否", }, { value: 1, label: "是", }],
            // onlineStateList: [{ value: 0, label: "不在线", }, { value: 1, label: "在线", }],
            onlineStateList: [],
            //
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            
        };
    },
    methods: {
        
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    this.rowInfo.title = "个性签名";
                    this.rowInfo.content = row.personalSignature;
                    this.dialogSeeContent = true;
                    break;

                default:
                    break;
            }
        },
        
        //选择
        selectUserHandle(row) {
            this.$emit("changeSelectUser", row);
        },

    },
}
</script>

<style lang="scss" scoped>
.table-td-thumb {
    display: block;
    margin: auto;
    max-width: 38px;
    max-height: 38px;
    font-size: 0.2rem;
}

.image-slot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f7fa;
}
</style>