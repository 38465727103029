<template>
    <div class="container">
        <div class='page-container'>
            <div class="search-box">
                <el-form :inline="true" class="fr-s-s">
                    <el-form-item>
                        <el-input size="small" v-model="query.username" clearable placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" plain type="primary" :loading="showLoading"
                            @click="refreshData">查询</el-button>
                    </el-form-item>
                    <el-form-item v-if="permission.expertSave">
                        <el-button size="small" plain type="primary" :loading="showLoading"
                            @click="onAdd">添加</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="table-box">
                <el-table ref="table" :data="tableData" border :row-key="'aid'" v-loading="showLoading"
                    :height="tableHeight">
                    <el-table-column prop="advertID" label="编号" align="center" width="60">
                        <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                    </el-table-column>
                    <el-table-column prop="name" label="姓名">
                        <template slot-scope="scope">{{ scope.row.name || '--' }}</template>
                    </el-table-column>
                    <el-table-column prop="username" label="用户名">
                        <template slot-scope="scope">{{ scope.row.username || '--' }}</template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号">
                        <template slot-scope="scope">{{ scope.row.mobile || '--' }}</template>
                    </el-table-column>
                    <el-table-column prop="email" label="邮箱">
                        <template slot-scope="scope">{{ scope.row.email || '--' }}</template>
                    </el-table-column>
                    <!-- <el-table-column prop="roleSigns" label="角色" min-width="180">
                        <template slot-scope="scope">{{ scope.row.roleList | formatRule }}</template>
                    </el-table-column> -->
                    <el-table-column label="是否可用" align="center">
                        <template slot-scope="scope">
                            <i :class="[scope.row.status == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120" fixed="right" align="center">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                                v-if="permission.expertUpdate">
                                <el-button class="success" icon="el-icon-edit-outline" type="text"
                                    @click="clickEditInfo(scope.row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top"
                                v-if="permission.expertRemove">
                                <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                                    @confirm="clickDelInfo(scope.row, scope.$index)">
                                    <el-button icon="el-icon-delete" slot="reference" type="text"
                                        class="danger"></el-button>
                                </el-popconfirm>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="修改密码" placement="top"
                                v-if="permission.resetPwd">
                                <el-button class="success" icon="el-icon-key" type="text"
                                    @click="onEditAdminPwd(scope.row)"></el-button>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="选择" placement="top"
                                v-if="permission.selectAgent">
                                <el-button icon="el-icon-circle-check" type="text" class="success"
                                    @click="selectAgentHandle(scope.row)"></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                    layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
                </el-pagination>

            </div>
        </div>

        <el-dialog :title="`${addOrEditModel.userId ? '编辑' : '添加'}专家`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form class="form-wrap" label-position="left" label-width="100px" ref="addOrEditModel"
                :model="addOrEditModel" :rules="addOrEditRules">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addOrEditModel.name" prefix-icon="el-icon-user" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="addOrEditModel.username" prefix-icon="el-icon-user"
                        placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input maxlength="11" oninput="value=value=value.replace(/[^0-9]/g,'')"
                        v-model="addOrEditModel.mobile" prefix-icon="el-icon-phone" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="password" v-if="!addOrEditModel.userId">
                    <el-input v-model="addOrEditModel.password" prefix-icon="el-icon-key" show-password
                        placeholder="请输入登录密码"></el-input>
                </el-form-item>
                <el-form-item label="重复密码" prop="repeatPwd" class="is-required" v-if="!addOrEditModel.userId">
                    <el-input v-model="addOrEditModel.repeatPwd" prefix-icon="el-icon-key" show-password
                        placeholder="请重复登录密码"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addOrEditModel.email" prefix-icon="el-icon-message"
                        placeholder="请输入邮箱"></el-input>
                </el-form-item>
                <el-form-item label="是否可用" required>
                    <el-checkbox v-model="addOrEditModel.status" :true-label="1" :false-label="0"></el-checkbox>
                </el-form-item>
                <el-form-item label="关联用户" prop="tUserId">
                    <el-input style="width: 320px" v-model="addOrEditModel.tUserId" placeholder="请选择用户"
                        disabled></el-input>
                    <el-button style="margin-left: 25px;" plain type="primary"
                        @click="clickSelectUserBtn(1)">选择</el-button>
                    <el-button style="margin-left: 25px;" plain type="primary"
                        @click="clickSelectUserBtn(2)">删除</el-button>
                </el-form-item>
                <!-- <el-form-item label="所属角色" prop="roleIds">
            <el-checkbox-group v-model="checkList">
              <el-checkbox v-for="(i,v) in rolelist" :key="v" :label="i.roleId">{{i.roleName}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" :loading="showLoading" @click="clickAddOrEditConfirm">确定</el-button>
                    <el-button class="m-l-10" @click="resetAddOrEditData">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog :title="`修改密码`" :visible.sync="dialogEditAdminPwdFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen :close-on-click-modal="false">
            <el-form ref="editPwd" label-width="120px" :model="addOrEditModel" :rules="addOrEditRules">
                <el-form-item label="登录密码" prop="password" class="is-required">
                    <el-input v-model="addOrEditModel.password" prefix-icon="el-icon-key" show-password
                        placeholder="请输入登录密码"></el-input>
                </el-form-item>
                <el-form-item label="重复密码" prop="repeatPwd" class="is-required">
                    <el-input v-model="addOrEditModel.repeatPwd" prefix-icon="el-icon-key" show-password
                        placeholder="请重复登录密码"></el-input>
                </el-form-item>
                <el-form-item class="is-required">
                    <el-button class="m-l-10" type="primary" :loading="showLoading" :disabled="showLoading"
                        @click="onEditPwdSubmit">确 定</el-button>
                    <el-button @click="dialogEditAdminPwdFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 选择用户 -->
        <el-dialog title="选择用户" :visible.sync="dialogSelectUserVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <ExpertSelectUserList @changeSelectUser="handleChangeSelectUser"
                v-if="dialogSelectUserVisible">
            </ExpertSelectUserList>
        </el-dialog>

    </div>
</template>
<script>
import { getList } from '@/tools/getList.js'
import adminManager from "@/request/adminManager.js";
import ExpertSelectUserList from "@/components/page/system/expert/ExpertSelectUserList.vue";
export default {
    mixins: [getList],
    components: {
        ExpertSelectUserList,
    },
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
                if (regex.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入6到20位的数字字母混合密码'))
                }
            }
        }
        var validateRepeatPass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else {
                var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,20}$/
                if (regex.test(value)) {
                    if (value === this.addOrEditModel.password) {
                        callback()
                    } else {
                        callback(new Error('两次密码不一致'))
                    }
                } else {
                    callback(new Error('请输入6到20位的数字字母混合密码'))
                }
            }
        }
        var validateEmail = (rule, value, callback) => {
            if (value) {
                var regex = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
                if (regex.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入正确的邮箱'))
                }
            } else {
                callback()
            }
        }
        return {
            requst: {
                module: adminManager,
                url: 'expertList',
                addUrl: 'expertSave',
                updateUrl: 'expertUpdate',
                delUrl: 'expertRemove',
                keyId: 'userId'
            },
            query: {
                username: '', //搜索词
                roleId: 65,   //角色ID
            },
            rolelist: [],
            branchList: [],
            initModel: {
                userId: 0,
                username: '',//登录名
                name: '',//用户真实姓名
                password: '',//
                repeatPwd: '',//密码
                // deptId: 0,//
                // deptName: '',
                email: '',//邮箱
                mobile: '',//手机号
                status: 1,//状态 0:禁用，1:正常
                roleIds: '',//角色id 英文逗号拼接
                tUserId: '',
            },
            addOrEditRules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [{ required: true, validator: validatePass, trigger: 'blur' }],
                repeatPwd: [{ required: true, validator: validateRepeatPass, trigger: 'blur' }],
                email: [{ required: false, validator: validateEmail, trigger: 'blur' }],
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                roleIds: [{ required: true, message: '请选择角色', trigger: 'blur' }],

            },
            checkList: [],
            checkBranchList: [],
            //修改密码
            dialogEditAdminPwdFormVisible: false,
            addOrEditModel: {},
            addOrEditDialog: false, //添加、编辑弹窗
            //选择
            currentKey: 1,
            dialogSelectUserVisible: false,
        }
    },
    watch: {
        checkList(val) {
            console.log(val)
            this.addOrEditModel.roleIds = val.join(',')
        },
        checkBranchList(val) {
            console.log(val)
            this.addOrEditModel.branchCode = val.join(',')
        }
    },
    filters: {
        formatRule(val = []) {
            return val.filter(i => i.had).map(o => o.roleName).join(',') || '--'
        }
    },
    created: async function () {
        if (this.initModel) this.addOrEditModel = JSON.parse(JSON.stringify(this.initModel));
        // this.getRoleList();
    },
    methods: {
        onAdd() {
            this.addOrEditDialog = true
            // this.checkList = [65]
            // this.addOrEditModel.roleIds = "1"
        },
        // async getRoleList () {
        //   this.rolelist = []
        //   try {
        //     let { code, data } = await adminManager.sysRoleListv()
        //     if (code === 200) {
        //       this.rolelist = data
        //     }
        //   } catch (error) {
        //     console.log(error)
        //   }
        // },
        // 点击编辑信息
        async clickEditInfo(row) {
            try {
                this.addOrEditModel = this.$util.assignObj(this.addOrEditModel, row)
                // console.log('addOrEditModel', this.addOrEditModel, row);
                // this.checkList = row.roleList.filter(i => i.had).map(o => o.roleId)
                this.addOrEditDialog = true
                // this.$refs["addOrEditModel"].clearValidate();
            } catch (error) {
                console.log(error)
            }
        },

        // 点击删除信息
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await this.requst.module[this.requst.delUrl]({ id: row[this.requst.keyId] });
                if (code === 200) {
                    this.$message.success(message);
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 点击添加、编辑----确认按钮
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.showLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
                        let data = await this.requst.module[this.addOrEditModel[this.requst.keyId] > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success' });
                            this.refreshData() //刷新列表数据
                            this.resetAddOrEditData() //清除添加、编辑数据
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.showLoading = false
                    }

                }
            })
        },
        // 清除添加/编辑数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.addOrEditModel = JSON.parse(JSON.stringify(this.initModel));
            this.checkList = [];
            this.initModel = {
                userId: 0,
                username: '',//登录名
                name: '',//用户真实姓名
                password: '',//
                repeatPwd: '',//密码
                // deptId: 0,//
                // deptName: '',
                email: '',//邮箱
                mobile: '',//手机号
                status: 1,//状态 0:禁用，1:正常
                roleIds: '',//角色id 英文逗号拼接
                tUserId: '',

            };
        },
        //修改密码
        onEditAdminPwd(row) {
            this.addOrEditModel.userId = row.userId
            this.addOrEditModel.password = '';
            this.addOrEditModel.repeatPwd = '';
            this.dialogEditAdminPwdFormVisible = true
        },
        async onEditPwdSubmit() {
            this.$refs.editPwd.validate(async valid => {
                if (valid) {
                    try {
                        this.showLoading = true
                        let { code, message } = await adminManager.sysUserAdminResetPwd({ pwdNew: this.addOrEditModel.password, 'userDO.userId': this.addOrEditModel.userId })
                        if (code === 200) {
                            this.$message.success(message);
                            this.refreshData() //刷新列表数据
                            this.resetAddOrEditData() //清除添加、编辑数据
                            this.dialogEditAdminPwdFormVisible = false
                        }
                    } finally {
                        this.showLoading = false;
                    }
                }
            })
        },

        // 添加、编辑选择用户
        clickSelectUserBtn(select_type) {
            if (select_type == 1) {
                if (!this.permission.selectUserList) return this.$message.error('您没有选择权限！');
                this.dialogSelectUserVisible = true  
            } else if (select_type == 2) {
                this.addOrEditModel.tUserId = 0   
            }
        },
        // 选择专家
        handleChangeSelectUser(row) {
            this.addOrEditModel.tUserId = row.userId   
            this.dialogSelectUserVisible = false  
        },


    }
}
</script>
<style scoped>
.pageblock {
    text-align: right;
    margin-top: 5px;
}
</style>