<template>
  <div class='container'>
    <div class='page-container'>
      <el-form :inline="true" class=" page-search">
        <el-form-item>
          <el-input type="text" placeholder="请输入关键字" v-model="query.key_words" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="clickAddTableInfo">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border :row-key="'roleId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column label="编号" align="center" width="60" prop="roleId">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="角色名" prop="roleName" min-width="140"></el-table-column>
        <el-table-column label="备注" prop="remark" min-width="160"></el-table-column>
        <el-table-column label="权限" prop="menuIds" width="120">
          <template slot-scope="scope">{{ scope.row.menuIds || '--' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button class="success" type="text" icon="el-icon-edit-outline"
                @click="clickEditTableInfo(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.roleId != 63 && scope.row.roleId != 64 && scope.row.roleId != 65 && scope.row.roleId != 66 && scope.row.roleId != 1"
              class="item" effect="dark" content="删除" placement="top">
              <el-popconfirm title="您确定要删除么？" icon-color="red" @confirm="clickDelTableInfo(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger"></el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination> -->
    </div>

    <!-- 添加/编辑角色信息 -->
    <el-dialog :title="`${addOrEditModel.roleId ? '编辑' : '添加'}角色信息`" :visible.sync="addOrEditDialog" :modal="false"
      :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form class="form-wrap" label-position="left" label-width="90px" ref="addOrEditModel" :model="addOrEditModel"
        :rules="addOrEditRules">
        <el-form-item label="角色名" prop="roleName">
          <el-input :disabled="addOrEditModel.roleId == 63 || addOrEditModel.roleId == 64" v-model="addOrEditModel.roleName"
            placeholder="请输入角色名"></el-input>
        </el-form-item>
        <el-form-item label="角色标识" prop="roleSign">
          <el-input v-model="addOrEditModel.roleSign" placeholder="请输入角色标识"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" autosize v-model="addOrEditModel.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限" prop="menuIds">
          <el-tree ref="menuTree" v-loading="showLoading" :data="menuList" show-checkbox node-key="id"
            :props="defaultProps" @check="checkMenuData" :default-checked-keys="defaultCheckedKeys"
            :default-expanded-keys="defaultShowNodes"></el-tree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">提交</el-button>
          <!-- <el-button class="m-l-10" @click="clickAddOrEditCancel">取 消</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import adminManager from "@/request/adminManager.js";
import { getList } from '@/tools/getList.js'
import bus from '@/components/common/bus.js';
export default {
  mixins: [getList],
  // import引入的组件需要注入到对象中才能使用
  components: {

  },
  data() {
    // 这里存放数据
    return {
      menuList: [],
      requst: {
        module: adminManager,
        url: 'sysRoleListv',
      },
      query: {
        key_words: '',    //关键词
      },
      addOrEditDialog: false, //添加、编辑角色弹窗
      addOrEditModel: {
        roleId: 0,   //权限ID（角色ID）
        roleName: '',     //权限名字（角色名称）
        remark: '',    //备注
        roleSign: '',    //权限签名，英文字符串
        menuIds: '',     //分配的菜单id，英文逗号拼接
      },
      addOrEditRules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        roleSign: [{ required: true, message: '请输入角色标识', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        menuIds: [{ required: true, message: '请输入菜单权限', trigger: 'blur' }],
      },
      addOrEditBtnLoading: false,
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      defaultShowNodes: [],   //默认展开第一层
      defaultCheckedKeys: [],    //编辑数据时默认选中key ID数据
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    menuList: {
      handler() {
        this.menuList.forEach((item) => {
          this.defaultShowNodes.push(item.id);
        });
      },
      deep: true,
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    // await this.getMenuTreeData();    //获取菜单权限数据
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  // 方法集合
  methods: {
    // 获取菜单权限数据
    async getMenuTreeData(role_id = -1) {
      this.showLoading = true
      try {
        let { code, data, message } = await adminManager.sysMenuTreeByRole(role_id)
        console.log('获取菜单权限数据', data)
        if (code == 200) {
          this.menuList = [data]    //data.children 
          if (role_id > 0) this.getHandleCheckedKeysData([data]);    //处理编辑时选中数据
        } else {
          this.menuList = []
        }
      } catch (error) {
        console.log(error)
        this.menuList = []
      } finally {
        this.showLoading = false
      }
    },
    // 处理编辑时选中数据
    getHandleCheckedKeysData(menu_data) {
      // console.log('处理编辑时选中数据', menu_data)
      this.defaultCheckedKeys = this.getAllNodes(menu_data)
      this.$nextTick(() => {
        this.checkMenuData()
      })
    },
    getAllNodes(node = [], arr = []) {
      for (let item of node) {
        if (item.state.selected && item.id > 0) arr.push(Number(item.id));
        let parentArr = []
        if (item.children) parentArr.push(...item.children)
        if (parentArr && parentArr.length) this.getAllNodes(parentArr, arr)
      }
      return arr
    },



    // 点击添加角色信息
    clickAddTableInfo() {
      this.addOrEditDialog = true
      this.getMenuTreeData();    //获取菜单权限数据
    },
    // 点击编辑角色信息
    clickEditTableInfo(row) {
      console.log('点击编辑角色信息', row)
      this.getMenuTreeData(row.roleId)     //获取菜单权限数据
      this.addOrEditDialog = true
      this.addOrEditModel = {
        roleId: row.roleId,    //角色ID
        roleName: row.roleName,    //角色名称
        roleSign: row.roleSign,   //权限签名，英文字符串
        remark: row.remark,    //备注
        menuIds: row.menuIds || ''
      }

    },
    // 点击删除角色信息
    async clickDelTableInfo(row, index) {
      console.log('点击删除角色信息', row, index)
      try {
        let { code, data, message } = await adminManager.sysRoleRemove({ id: row.roleId })
        if (code === 200) {
          this.$message.success(message);
          this.delRefresh(index);
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 菜单权限---当复选框被点击的时候触发
    checkMenuData(current_node, check_node) {
      // console.log('菜单权限---当复选框被点击的时候触发',current_node,check_node)
      this.addOrEditModel.menuIds = this.$refs.menuTree.getCheckedKeys().concat(this.$refs.menuTree.getHalfCheckedKeys()).join(',');
      // console.log('获取到当前选中节点的id', this.addOrEditModel.menuIds)
    },

    // 点击添加/编辑---取消按钮
    clickAddOrEditCancel() {
      this.resetAddOrEditData();   //清除添加/编辑数据
    },
    // 点击添加/编辑---确认按钮
    clickAddOrEditConfirm() {
      this.$refs.addOrEditModel.validate(async (valid) => {
        if (valid) {
          this.addOrEditBtnLoading = true
          let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
          if (dataValue.roleId > 0) {
            this.postEditRoleInfo(dataValue)    //编辑角色接口
          } else {
            this.postAddRoleInfo(dataValue)   //添加角色接口
          }
        }
      })
    },
    // 添加角色接口
    async postAddRoleInfo(data_value) {
      console.log('添加角色接口参数', data_value)
      try {
        let { code, data, message } = await adminManager.sysRoleSave(data_value)
        if (code == 200) {
          this.$message.success('添加成功');
          this.refreshData()     //刷新列表数据
          this.resetAddOrEditData()    //清除添加、编辑数据
          bus.$emit('refreshTabTree')    //刷新菜单
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.addOrEditBtnLoading = false
      }
    },

    // 编辑角色接口
    async postEditRoleInfo(data_value) {
      console.log('编辑角色接口接口参数', data_value)
      try {
        let { code, data, message } = await adminManager.sysRoleUpdate(data_value)
        if (code == 200) {
          this.$message.success('修改成功');
          this.refreshData()     //刷新列表数据
          this.resetAddOrEditData()    //清除添加、编辑数据
          bus.$emit('refreshTabTree')    //刷新菜单
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.addOrEditBtnLoading = false
      }
    },

    // 清除添加/编辑数据
    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.defaultCheckedKeys = []    //默认选中
      this.menuList = []     //菜单权限数据
      this.addOrEditModel = {
        roleId: 0,   //权限ID（角色ID）
        roleName: '',     //权限名字（角色名称）
        remark: '',    //备注
        roleSign: '',    //权限签名，英文字符串
        menuIds: '',     //分配的菜单id，英文逗号拼接
      }

    },
  },
  // 生命周期 - 创建之前
  beforeCreate() { },
  // 生命周期 - 挂载之前
  beforeMount() { },
  // 生命周期 - 更新之前
  beforeUpdate() { },
  // 生命周期 - 更新之后
  updated() { },
  // 生命周期 - 销毁之前
  beforeDestroy() { },
  // 生命周期 - 销毁完成
  destroyed() { },
  //  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() { },
}
</script>

<style scoped></style>
