<template>
	<div class='container'>
		<div class='page-container'>
			<el-form :inline="true" class="fr-s-s">
				<el-form-item>
					<el-button size="small" plain type="primary" :loading="showLoading" @click="clickAddInfo">添加</el-button>
				</el-form-item>
			</el-form>
			<el-table ref="table" :data="tableData" border :row-key="'id'" v-loading="showLoading" :height="tableHeight" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column label="编号" type="index" :index="indexMethod" align="center" width="60">
				</el-table-column>
				<el-table-column prop="text" label="名称" min-width="120"></el-table-column>
				<el-table-column prop="noticeContent" label="图标" align="center" width="80">
					<template slot-scope="scope">
						<i :class="scope.row.attributes.icon" style="font-size: 16px;"></i>
					</template>
				</el-table-column>

				<el-table-column prop="addTime" label="类型" width="80" align="center">
					<template slot-scope="scope">{{ scope.row.type == 0?'目录':scope.row.type == 1?'菜单':'按钮' }}</template>
				</el-table-column>
				<el-table-column prop="url" label="地址" min-width="140">
					<template slot-scope="scope">{{scope.row.attributes.url}}</template>
				</el-table-column>
				<el-table-column prop="perms" label="权限标识" min-width="140" >
					<template slot-scope="scope">{{scope.row.attributes.perms}}</template>
				</el-table-column>
				<el-table-column label="操作" width="120" fixed="right">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="添加" placement="top" v-if="scope.row.type != 2">
							<el-button class="success" icon="el-icon-plus" type="text" @click="clickAddChildrenInfo(scope.row)"></el-button>
						</el-tooltip>
						
						<el-tooltip class="item" effect="dark" content="编辑" placement="top">
							<el-button class="success" icon="el-icon-edit-outline" type="text" @click="clickEditInfo(scope.row)" style="margin-left: 0;"></el-button>
						</el-tooltip>
						
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-popconfirm title="您确定要删除么？"  icon="el-icon-info" icon-color="red" @confirm="clickDelInfo(scope.row,scope.$index)">
								<el-button icon="el-icon-delete" slot="reference" type="text" class="danger"></el-button>
							</el-popconfirm>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>

		</div>

		<!-- 添加、编辑 -->
		<el-dialog :title="`${dialogType==3?'编辑':'添加'}${dialogName}`" :visible.sync="addOrEditDialog" :modal="false"
			:destroy-on-close="true" fullscreen @close="resetAddOrEditData">
			<el-form label-width="80px" ref="addOrEditModel" :model="addOrEditModel" :rules="addOrEditRules"
				label-position="left" class="form-wrap">
				<el-form-item label="上级菜单" prop="notice_title">
					<el-input :value="dialogType==1?'根目录': addOrEditModel.parentMenu" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input v-model="addOrEditModel.name"></el-input>
				</el-form-item>
				<el-form-item label="链接地址" prop="url">
					<el-input v-model="addOrEditModel.url"></el-input>
				</el-form-item>
				<el-form-item label="权限标识" prop="perms" v-if="addOrEditModel.type==2">
					<el-input v-model="addOrEditModel.perms"></el-input>
				</el-form-item>
				<el-form-item label="排序号" prop="orderNum">
					<el-input type="number" v-model="addOrEditModel.orderNum" placeholder="请输入排序号"></el-input>
				</el-form-item>
				<el-form-item label="图标" prop="icon" v-if="dialogType == 1 || (dialogType == 3&&addOrEditModel.type==0)">
					<el-input v-model="addOrEditModel.icon" placeholder="例如：el-icon-s-custom">
					</el-input>
					<!-- <el-button type="primary" style="margin-left: 10px;">选择图标</el-button> -->
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="showLoading" @click="clickAddOrEditConfirm">确定</el-button>
					<el-button class="m-l-10" @click="clickAddOrEditCancel">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	// 例如：import 《组件名称》 from '《组件路径》'
	import {
		getList
	} from '@/tools/getList.js'
	import systemManager from "@/request/systemManager.js";
	import bus from '@/components/common/bus.js';
	export default {
		mixins: [getList],
		// import引入的组件需要注入到对象中才能使用
		components: {},
		data() {
			var validateUrl = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入链接"));
				} else {
					// callback();
					var regex = /^\/[a-z0-9]/;
					if (regex.test(value)) {
						callback();
					} else {
						callback(new Error("请输入正确的链接"));
					}
				}
			};
			// 这里存放数据
			return {
				requst: {
					module: systemManager,
					url: 'getsystemmenulist'
				},
				dialogType: 1, //1 添加根目录      2添加菜单   3编辑
				dialogName: '',
				addOrEditDialog: false, //添加、编辑弹窗
				addOrEditModel: {
					parentId: 0,
					parentMenu: '',
					menuId: 0,
					name: '',
					url: '',
					perms: '',
					type: 0,
					icon: '',
					orderNum: 0
				},
				addOrEditRules: {
					name: [{
						required: true,
						message: "请输入菜单名称",
						trigger: "blur"
					}],
					url: [{
						required: true,
						message: "请输入链接",
						trigger: "blur"
					},{
						validator: validateUrl
					}],
					perms: [{
						required: true,
						message: "请输入权限标识",
						trigger: "blur"
					}],
					orderNum: [{
						required: true,
						message: "请输入排序号",
						trigger: "blur"
					}],
					icon: [{
						required: true,
						message: "请输入图标样式",
						trigger: "blur"
					}],
				},

			}
		},
		// 监听属性 类似于data概念
		computed: {},
		// 监控data中的数据变化
		watch: {},
		// 生命周期 - 创建完成（可以访问当前this实例）
		created() {

		},
		// 生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {},
		// 方法集合
		methods: {
			indexMethod(index) {
				return index + 1;
			},

			// 点击添加
			clickAddInfo() {
				this.dialogType = 1
				this.dialogName = '目录'
				this.addOrEditModel.type = 0
				this.addOrEditDialog = true
			},

			clickAddChildrenInfo(row) {
				console.log(row.type)
				this.dialogType = 2
				this.dialogName = row.type == 0?'菜单':row.type == 1?'按钮':''
				this.addOrEditModel.type = row.type+1
				this.addOrEditModel.parentId = row.id
				this.addOrEditModel.parentMenu = row.text
				this.addOrEditDialog = true
			},

			// 点击编辑信息
			clickEditInfo(row) {
				console.log(row)
				this.dialogType = 3
				this.dialogName = row.type == 0?'目录':row.type == 1?'菜单':'按钮'
				this.addOrEditModel = {
					parentId: row.parentId,
					parentMenu: row.parentName,
					menuId: row.id,
					name: row.text,
					url: row.attributes.url,
					perms: row.attributes.perms,
					type: row.type,
					icon: row.attributes.icon,
					orderNum: row.orderNum
				}
				this.addOrEditDialog = true
			},
			// 点击添加、编辑---取消
			clickAddOrEditCancel() {
				this.resetAddOrEditData()
			},
			// 点击添加、编辑---确定
			clickAddOrEditConfirm() {
				this.$refs.addOrEditModel.validate(async (valid) => {
					if (valid) {
						console.log(this.addOrEditModel)
						this.showLoading = true
						let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
						if (this.dialogType == 3) {
							this.postEditRegionInfo(dataValue) //编辑
						} else {
							this.postAddRegionInfo(dataValue) //添加
						}
					}
				})
			},
			// 添加接口
			async postAddRegionInfo(data_value) {
				console.log('添加接口', data_value)
				try {
					let data = await systemManager.addSystemMenuInfo(data_value)
					console.log(data)
					if (data.code == 200) {
						this.$message({
							message: data.message,
							type: 'success'
						});
						this.refreshData() //刷新列表数据
						this.resetAddOrEditData() //清除添加、编辑数据
						bus.$emit('refreshTabTree')
					}
				} catch (error) {
					console.log(error)
					this.showLoading = false
				} 
			},
			// 编辑接口
			async postEditRegionInfo(data_value) {
				console.log('编辑接口', data_value)
				try {
					let data = await systemManager.editSystemMenuInfo(data_value)
					console.log(data)
					if (data.code == 200) {
						this.$message({
							message: data.message,
							type: 'success'
						});
						this.refreshData() //刷新列表数据
						this.resetAddOrEditData() //清除添加、编辑数据
						bus.$emit('refreshTabTree')
					}
				} catch (error) {
					this.showLoading = false
					console.log(error)
				}
			},

			// 清除添加、编辑数据
			resetAddOrEditData() {
				this.addOrEditDialog = false
				this.addOrEditModel = {
					parentId: 0,
					parentMenu: '',
					menuId: 0,
					name: '',
					url: '',
					perms: '',
					type: 0,
					icon: '',
					orderNum: 0
				}
			},

			// 点击删除
			async clickDelInfo(row, index) {
				try {
					let {
						code,
						message
					} = await systemManager.delSystemMenuInfo({
						id: row.id
					});
					if (code === 200) {
						this.$message.success(message);
						this.refreshData()
						bus.$emit('refreshTabTree')
					}
				} catch (error) {
					console.log(error)
				}
			},

		},
		// 生命周期 - 创建之前
		beforeCreate() {},
		// 生命周期 - 挂载之前
		beforeMount() {},
		// 生命周期 - 更新之前
		beforeUpdate() {},
		// 生命周期 - 更新之后
		updated() {},
		// 生命周期 - 销毁之前
		beforeDestroy() {},
		// 生命周期 - 销毁完成
		destroyed() {},
		//  // 如果页面有keep-alive缓存功能，这个函数会触发
		activated() {},
	}
</script>

<style scoped>
	.w-50 {
		width: 50%;
	}
</style>
